import { RewardType, GameType, Reward } from 'ui/types/Games'
import { Assignment } from 'ui/types/GameTypes'

import { GameResponse, Rules, StatusType } from '../../types'

export interface NormalizedGame {
  assign: Assignment
  awardCount?: number
  countActivitiesAfterGuide?: boolean
  coverImageUrl?: string | null
  earnedReward?: Reward
  endDateTime: Date
  gameType: GameType
  goal: number
  hasThreshold: boolean
  id: number
  isRewardImmediatelyDistributed?: boolean
  isSingleGoalReward?: boolean
  limitWinners?: number
  metric: string
  name: string
  numberOfWinners?: number
  place: number
  playerCount?: number
  prizeAmount?: number
  prizeType: RewardType
  rewards: Array<{ type: RewardType; value: any }>
  rewardsAwardedCount?: number
  rules: Rules
  score: number
  startDateTime: Date
  status: StatusType
  threshold: number | null
  totalScore: number
  unit: string
  userId?: number
  userImageUrl?: string
  userName?: string
}

export const normalizeGame = (game: GameResponse): NormalizedGame => {
  return {
    assign: game.assign,
    awardCount: game.playerInformation.awardCount,
    countActivitiesAfterGuide: game.countActivitiesAfterGuide,
    coverImageUrl: game?.coverImageUrl || null,
    earnedReward: game.playerInformation.earnedReward,
    endDateTime: game.expiresAt,
    gameType: game.type,
    goal: game.rules.goal,
    hasThreshold: game.playerInformation.hasThreshold,
    id: game.id,
    isRewardImmediatelyDistributed: game.isRewardImmediatelyDistributed,
    isSingleGoalReward: game.isSingleGoalReward,
    limitWinners: game.limitWinners,
    metric: game.rules.metric,
    name: game.name,
    numberOfWinners: game.numberOfWinners,
    place: game.playerInformation.ranking,
    playerCount: game?.playerCount,
    prizeAmount: parseInt(game.rewards[0].value),
    prizeType: game.rewards[0]?.type,
    rewards: game.rewards,
    rewardsAwardedCount: game?.rewardsAwardedCount,
    rules: game.rules,
    score: game.playerInformation.score,
    startDateTime: game.startsAt,
    status: game.status,
    threshold: game.playerInformation.threshold,
    totalScore: game.playerInformation.totalScore,
    unit: game.rules.unit,
    userId: game.winner?.id,
    userImageUrl: game.winner?.imageUrl,
    userName: game.winner?.name,
  }
}
