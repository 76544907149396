import { Tab, TabList, Heading } from '@chakra-ui/react';
import styles from './GamesContainer.module.css';
interface GamesTabMenuProps {
  assumedUserName?: string;
  isMobile: boolean;
}
export const GamesTabMenu = ({
  assumedUserName,
  isMobile
}: GamesTabMenuProps) => {
  return <div className={styles.tabsMenuContainer} data-sentry-component="GamesTabMenu" data-sentry-source-file="GamesTabMenu.tsx">
      <TabList style={{
      display: 'flex',
      gap: '8px'
    }} data-sentry-element="TabList" data-sentry-source-file="GamesTabMenu.tsx">
        <Tab data-sentry-element="Tab" data-sentry-source-file="GamesTabMenu.tsx">
          <Heading as="h2" fontSize={isMobile ? 'md' : 'xl'} data-sentry-element="Heading" data-sentry-source-file="GamesTabMenu.tsx">
            {assumedUserName ? `${assumedUserName}'s games` : 'My Games'}
          </Heading>
        </Tab>
        <Tab data-sentry-element="Tab" data-sentry-source-file="GamesTabMenu.tsx">
          <Heading as="h2" fontSize={isMobile ? 'md' : 'xl'} data-sentry-element="Heading" data-sentry-source-file="GamesTabMenu.tsx">
            Managed Games
          </Heading>
        </Tab>
      </TabList>
    </div>;
};