import { FC } from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { ICON_BY_NAME } from 'ui/components/Icons/Icon';
import { CbTableSelectorPopover } from '../CbTableSelectorPopover';
import { CbArrow } from '../../Buttons/CbArrow';
import { ButtonVariant } from 'ui/components/Buttons/CbButton';
export interface CbTablePaginationProps extends FlexProps {
  buttonStyle?: 'solid' | 'outline';
  buttonSize?: string | number;
  currentPage?: number;
  label?: string;
  hasPagesPopover?: boolean;
  isDarkMode?: boolean;
  totalEntries?: number;
  numberOfRows: number;
  handlePageChange: (pageNumber: number) => void;
}
export const CbTablePagination: FC<CbTablePaginationProps> = ({
  buttonStyle = 'solid',
  buttonSize = 12,
  currentPage = 0,
  hasPagesPopover = true,
  isDarkMode,
  label = 'Items',
  totalEntries = 0,
  numberOfRows = 0,
  handlePageChange,
  ...props
}) => {
  const totalPages = Math.ceil(totalEntries / numberOfRows);
  const startRow = currentPage * numberOfRows + 1;
  const endRow = Math.min(startRow + numberOfRows - 1, totalEntries);
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage + 1 >= totalPages;
  const paginationLabel = totalEntries ? `${startRow} - ${endRow} of ${totalEntries} ${label}` : `No ${label}`;
  const pages = Array.from({
    length: totalPages
  }).map((_, i) => i + 1);
  let buttonVariant: ButtonVariant = isDarkMode ? 'ghost-on-dark' : 'outline';
  const onPrevClick = (): void => {
    handlePageChange(currentPage - 1);
  };
  const onNextClick = (): void => {
    handlePageChange(currentPage + 1);
  };
  const onChange = (option): void => {
    handlePageChange(Number(option) - 1);
  };
  return <Flex color={isDarkMode ? 'text.primaryWhite' : 'text.primary'} direction="row" justifyContent="space-between" alignItems="center" {...props} data-sentry-element="Flex" data-sentry-component="CbTablePagination" data-sentry-source-file="CbTablePagination.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="CbTablePagination.tsx">{paginationLabel}</Text>
      <Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="CbTablePagination.tsx">
        {hasPagesPopover && <Flex alignItems="center">
            <CbTableSelectorPopover isDarkMode={isDarkMode} options={pages} trigger={<Flex justifyContent="space-between" alignItems="center" as="button" height={10} paddingX={4} borderRadius={50} borderColor="cloud.400" borderWidth={1} backgroundColor="transparent" _hover={{
          color: 'cloud.400'
        }}>
                  <Text marginRight={4}>{currentPage + 1}</Text>
                  <ICON_BY_NAME.chevrondown height={3} width={3} />
                </Flex>} onChange={onChange} />
            <Text marginLeft={2} marginRight={8}>{` of ${totalPages} pages`}</Text>
          </Flex>}
        <Flex data-sentry-element="Flex" data-sentry-source-file="CbTablePagination.tsx">
          <CbArrow data-testid="left-pagination-button" aria-label="Previous" direction="left" isDisabled={isFirstPage} marginRight={2} variant={buttonVariant} onClick={onPrevClick} data-sentry-element="CbArrow" data-sentry-source-file="CbTablePagination.tsx" />
          <CbArrow data-testid="right-pagination-button" aria-label="Next" direction="right" isDisabled={isLastPage} variant={buttonVariant} onClick={onNextClick} data-sentry-element="CbArrow" data-sentry-source-file="CbTablePagination.tsx" />
        </Flex>
      </Flex>
    </Flex>;
};