import { Box, Flex, Heading } from '@chakra-ui/react';
import { JoinableGameCard } from './JoinableGameCard';
import { useFeatures } from 'hooks';
import { Feature } from 'types';
import { Joinable } from 'types/Joinable';
interface JoinableGameProps {
  joinableGames: Joinable[];
}
export const JoinableGames = ({
  joinableGames
}: JoinableGameProps) => {
  const hasGameGuides = useFeatures(Feature.GameGuides);
  if (!hasGameGuides || joinableGames.length === 0) {
    return <></>;
  }
  return <Box paddingX={{
    base: 4,
    xl: 0
  }} data-sentry-element="Box" data-sentry-component="JoinableGames" data-sentry-source-file="JoinableGames.tsx">
      <Flex justifyContent="space-evenly" marginTop={8} data-sentry-element="Flex" data-sentry-source-file="JoinableGames.tsx">
        <Box width={{
        base: '100%'
      }} data-sentry-element="Box" data-sentry-source-file="JoinableGames.tsx">
          <Heading as="h2" color="text.primaryWhite" fontSize="2xl" marginBottom={4} paddingX={{
          base: 0,
          lg: 2,
          xl: 6
        }} data-sentry-element="Heading" data-sentry-source-file="JoinableGames.tsx">
            Available Guides
          </Heading>
          <Flex direction={'column'} gap={4} data-sentry-element="Flex" data-sentry-source-file="JoinableGames.tsx">
            {joinableGames.map(game => game.guides.map(guide => <JoinableGameCard key={guide.id} guide={guide} coverImageUrl={game.coverImageUrl} name={game.name} rewards={game.rewards} type={game.type} />))}
          </Flex>
        </Box>
      </Flex>
    </Box>;
};