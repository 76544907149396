import { Box } from '@chakra-ui/react';
import { FC, SyntheticEvent, useContext } from 'react';
import { GamesContainer } from './GamesContainer';
import { Header } from './Header';
import { Joinable } from 'types/Joinable';
import { JoinableGames } from './JoinableGames';
import { MPThemeContext } from '../../providers';
import { NormalizedGame } from 'containers/GamesPageContainer/normalizers';
export interface GamesPageProps {
  activeFilter: string;
  currentPage: number;
  games: NormalizedGame[];
  managedGames?: NormalizedGame[];
  joinableGames?: Joinable[];
  assumedUserName?: string;
  isPending?: boolean;
  numberOfRows: number;
  totalEntries: number;
  onFilterChange: (value: string) => void;
  onGameClick?: (id: number) => void;
  onPageChange: (pageNumber: number) => void;
  onWinningsClick?: (event: SyntheticEvent) => void;
}
export const GamesPage: FC<GamesPageProps> = ({
  activeFilter,
  currentPage,
  games,
  managedGames,
  joinableGames,
  assumedUserName,
  isPending,
  numberOfRows,
  totalEntries,
  onFilterChange,
  onGameClick,
  onPageChange,
  onWinningsClick
}) => {
  const {
    isMobile
  } = useContext(MPThemeContext);
  return <Box data-sentry-element="Box" data-sentry-component="GamesPage" data-sentry-source-file="GamesPage.tsx">
      {/* Header */}
      <Header activeFilter={activeFilter} isMobile={isMobile} onFilterChange={onFilterChange} data-sentry-element="Header" data-sentry-source-file="GamesPage.tsx" />
      {/* Joinable Games */}
      {joinableGames && <JoinableGames joinableGames={joinableGames} />}
      {/* Games Container */}
      <GamesContainer activeFilter={activeFilter} assumedUserName={assumedUserName} currentPage={currentPage} games={games} isMobile={isMobile || false} isPending={isPending} managedGames={managedGames || []} numberOfRows={numberOfRows} onGameClick={onGameClick} onPageChange={onPageChange} onWinningsClick={onWinningsClick} totalEntries={totalEntries} data-sentry-element="GamesContainer" data-sentry-source-file="GamesPage.tsx" />
    </Box>;
};