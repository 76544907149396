import { useEffect, useState } from 'react';
import { forwardRef, SelectProps, Select } from '@chakra-ui/react';
import { removeSnakeCase, upFirst } from '../../helpers';
export type CbSelectOption = string | ({
  name: string;
  disabled?: boolean;
} & {
  [key: string]: any;
});
export interface CbSelectProps extends Omit<SelectProps, 'onChange, value'> {
  disabled?: boolean;
  onChange: (val: any) => void;
  options: CbSelectOption[];
  placeholder?: string;
  getValueFromOption?: (CbSelectOption: any) => string;
  textColor?: string;
  value: any;
}
export const CbSelect = forwardRef<CbSelectProps, 'select'>(({
  disabled = false,
  getValueFromOption = (val: any) => val,
  onChange,
  options,
  placeholder,
  size,
  textColor,
  value,
  ...props
}, ref) => {
  /* track a value as string that controls <select> active selection
   this is a workaround due to options often being an object, as HTML <option>
   is cast to a string value
  */
  const [selectedValue, setSelectedValue] = useState<string>();
  useEffect(() => {
    if (value) {
      setSelectedValue(getValueFromOption(value));
    } else {
      setSelectedValue(placeholder as string);
    }
  }, [placeholder, value]);
  const handleChange = ({
    target: {
      selectedIndex,
      value
    }
  }) => {
    setSelectedValue(value);
    if (onChange) {
      // retrieve option corresponding to index selected (account for placeholder Option)
      const optionIndex = placeholder ? selectedIndex - 1 : selectedIndex;
      const selectedOption = options[optionIndex];
      onChange(selectedOption);
    }
  };
  const placeholderOption = <option data-testid="placeholder-option" disabled key="placeholder" value={placeholder}>
        {placeholder}
      </option>;
  const renderOptions = () => {
    return options.map((opt, index) => {
      const label = typeof opt === 'string' ? opt : opt.name;
      const value = getValueFromOption(opt);
      const disabled = typeof opt === 'string' ? false : !!opt.disabled;
      return <option key={index} style={{
        color: 'black'
      }} value={value} disabled={disabled}>
            {upFirst(removeSnakeCase(label))}
          </option>;
    });
  };
  return <Select backgroundColor="inherit" border="1.5px solid" borderColor="cloud.300" borderRadius={99} data-testid="select" disabled={disabled} fontWeight="semibold" onChange={handleChange} ref={ref} size={size} value={value ? getValueFromOption(value) : selectedValue} _hover={{
    borderColor: 'cloud.400'
  }} _focus={{
    borderColor: 'chonky.500',
    borderWidth: '2px'
  }} {...props}>
        {placeholder && placeholderOption}
        {renderOptions()}
      </Select>;
});