import { Box } from '@chakra-ui/react';
import { CbColorBanner } from 'ui/components/CbColorBanner';
import { CbMobileFilterDateRange } from 'ui/components/CbMobileFilterDateRange';
import { CbSelect } from 'ui/components/CbSelect';
import { gamesFilterOptionValues } from '../../../types';
export interface HeaderProps {
  activeFilter: string;
  isMobile: boolean;
  onFilterChange: (value: string) => void;
}
export const Header = ({
  activeFilter,
  isMobile,
  onFilterChange
}) => {
  return <CbColorBanner background="gradient.darkModeBanner" title="Game Center" rightElement={isMobile ? <CbMobileFilterDateRange contentBackgroundColor="darkMode.500" defaultFilterValue={activeFilter} filterOptions={gamesFilterOptionValues} hasDateRange={false} onFilterChange={onFilterChange} /> : <>
            <Box width="180px">
              <CbSelect color="white" onChange={onFilterChange} options={gamesFilterOptionValues} value={activeFilter} variant="cb-input-outlineWhite" />
            </Box>
          </>} data-sentry-element="CbColorBanner" data-sentry-component="Header" data-sentry-source-file="Header.tsx" />;
};