import { FC, SyntheticEvent } from 'react';
import { ButtonVariant, CbButton, CbButtonProps } from '../CbButton';
import { ICON_BY_NAME } from '../../Icons/Icon';
export interface CbArrowProps extends Omit<CbButtonProps, 'children'> {
  'aria-label': string;
  direction?: 'left' | 'right';
  isDarkMode?: boolean;
  isDisabled: boolean;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}
export const CbArrow: FC<CbArrowProps> = ({
  'aria-label': ariaLabel,
  direction = 'right',
  isDarkMode,
  isDisabled = false,
  ...props
}) => {
  const variant: ButtonVariant = isDarkMode ? 'ghost-on-dark' : 'outline';
  return <CbButton aria-label={ariaLabel} isDisabled={isDisabled} variant={variant} round width="40px" height="40px" padding="0" {...props} data-sentry-element="CbButton" data-sentry-component="CbArrow" data-sentry-source-file="CbArrow.tsx">
      {direction === 'left' ? <ICON_BY_NAME.leftpagination marginRight="2px" height={2} width={2} /> : <ICON_BY_NAME.rightpagination marginLeft="2px" height={2} width={2} />}
    </CbButton>;
};