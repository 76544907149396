import { cloneElement, FC, MouseEvent, ReactElement } from 'react';
import { Box, Divider, Flex, Popover, PopoverTrigger, PopoverContent, PopoverBody, Text, useDisclosure } from '@chakra-ui/react';
import { ICON_BY_NAME } from 'ui/components/Icons/Icon';
export interface CbTableSelectorPopoverProps {
  isDarkMode?: boolean;
  options: string[] | number[];
  text?: string;
  trigger: ReactElement;
  value?: string;
  onChange?: (option: string) => void;
  hasIcon?: boolean | undefined;
}
export const CbTableSelectorPopover: FC<CbTableSelectorPopoverProps> = ({
  isDarkMode = false,
  options = [],
  trigger,
  value,
  onChange,
  hasIcon
}) => {
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const clonedTrigger = cloneElement(trigger, {
    onClick: (event: MouseEvent<HTMLElement>) => {
      trigger.props.onClick?.(event);
      onOpen();
    }
  });
  const handleClick = (option: string): void => {
    if (onChange != null && value !== option) {
      onChange(option);
    }
    onClose();
  };
  return <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start" data-sentry-element="Popover" data-sentry-component="CbTableSelectorPopover" data-sentry-source-file="CbTableSelectorPopover.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="CbTableSelectorPopover.tsx">{clonedTrigger}</PopoverTrigger>
      <Box zIndex="popover" data-sentry-element="Box" data-sentry-source-file="CbTableSelectorPopover.tsx">
        <PopoverContent maxHeight={64} overflowY="scroll" sx={{
        '::-webkit-scrollbar': {
          display: 'none'
        }
      }} marginLeft="10%" width={200} borderRadius={8} borderWidth="1px" borderColor={isDarkMode ? 'transparent' : 'cloud.200'} boxShadow="sm" backgroundColor={isDarkMode ? 'darkMode.500' : 'white'} _focus={{
        boxShadow: isDarkMode ? '0px 4.22702px 21.1351px 2.81801px rgba(0, 0, 0, 0.0900136) !important' : '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1) !important',
        outline: 'none'
      }} data-sentry-element="PopoverContent" data-sentry-source-file="CbTableSelectorPopover.tsx">
          <PopoverBody justifyContent="center" paddingY={0} data-sentry-element="PopoverBody" data-sentry-source-file="CbTableSelectorPopover.tsx">
            <Box data-sentry-element="Box" data-sentry-source-file="CbTableSelectorPopover.tsx">
              {(options as any[]).map?.(option => <Flex key={`${Math.random()}`} flexDirection="column">
                  <Flex as="button" onClick={() => handleClick(option)} paddingY={4} textAlign="left" justifyContent="space-between" alignItems="center">
                    <Text marginLeft={4} color={isDarkMode ? 'text.primaryWhite' : 'text.primary'}>
                      {option}
                    </Text>
                    {(hasIcon ?? false) && <ICON_BY_NAME.chevronright height={3} width={3} />}
                  </Flex>
                  <Divider borderColor={isDarkMode ? 'rgba(255,255,255,0.2)' : 'cloud.300'} />
                </Flex>)}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>;
};