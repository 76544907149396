import { FC, useRef, useEffect, useState } from 'react';
import { Card, Text, Box } from '@chakra-ui/react';
import Link from 'next/link';
import { GameType, Reward } from 'containers/GameWizard';
import { Guide } from 'types/Guide';
import styles from './JoinableGameCard.module.css';
import { gameDisplay } from 'ui/types/Games';
import { formatNumber } from 'ui/helpers';
import RaffleTicket from 'ui/assets/RaffleTicket';
import Coins from 'ui/assets/Coins';
import classNames from 'classnames';
interface JoinableGameCardProps {
  guide: Partial<Guide>;
  coverImageUrl: string | null;
  name: string;
  rewards: Reward[];
  type: GameType;
}
export const JoinableGameCard: FC<JoinableGameCardProps> = ({
  guide,
  coverImageUrl,
  name,
  rewards,
  type
}) => {
  const titleRef = useRef<HTMLParagraphElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [scrollDistance, setScrollDistance] = useState<string>('0%');
  useEffect(() => {
    const titleElement = titleRef.current;
    if (titleElement) {
      const titleWidth = titleElement.scrollWidth;
      const containerWidth = titleElement.clientWidth;
      setIsOverflowing(titleWidth > containerWidth);
      const calculatedScrollDistance = `calc(-${titleWidth}px + ${containerWidth}px - 4rem)`;
      setScrollDistance(calculatedScrollDistance);
    }
  }, []);
  return <Card direction="column" backgroundColor="darkMode.500" overflow="hidden" data-sentry-element="Card" data-sentry-component="JoinableGameCard" data-sentry-source-file="JoinableGameCard.tsx">
      <Link href={`/guides/${guide.id}`} data-sentry-element="Link" data-sentry-source-file="JoinableGameCard.tsx">
        <div className={styles.container}>
          <Box backgroundImage={`url("${coverImageUrl || gameDisplay[type].image}")`} backgroundPosition="center" backgroundSize="cover" height="100%" width="16dvw" maxWidth="16dvw" filter="brightness(0.5)" data-sentry-element="Box" data-sentry-source-file="JoinableGameCard.tsx" />
          <div className={styles.textContent}>
            {isOverflowing && <div className={classNames(styles.fader, styles.faderLeft)} />}
            <p className={classNames(styles.title, {
            [styles.animation]: isOverflowing
          })} ref={titleRef} style={{
            '--scroll-distance': scrollDistance
          } as React.CSSProperties}>
              {guide.title}
            </p>
            <p className={styles.subtitle}>
              Complete to join <span>{name}</span>
            </p>
            {isOverflowing && <div className={classNames(styles.fader, styles.faderRight)} />}
          </div>
          <div className={styles.rewards}>
            <p style={{
            fontSize: '0.75rem'
          }}>Game Rewards:</p>
            {rewards.map((reward, index) => <Box className={styles.reward} key={index}>
                {reward.type === 'token_jackpot' ? <>
                    <RaffleTicket width={9} height="auto" marginRight={2} />
                    <Text color="white">
                      {`x ${formatNumber(Number(reward.value))}`}
                    </Text>
                  </> : <>
                    <Coins width={6} height="auto" marginRight={2} />
                    <Text color="white">
                      {formatNumber(Number(reward.value))}
                    </Text>
                  </>}
              </Box>)}
          </div>
        </div>
      </Link>
    </Card>;
};